@import 'base/app';

.not-found{
    &__container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 75px;
        @include _1920{
            padding-left: calcFluid(50, 75);
        }
        @include _992{
            flex-direction: column;
            padding-left: 0px;
            position: relative;
        }
    }
    &__content{
        max-width: 750px;
        @include _1920{
            max-width: calcFluid(500, 750);
        }
        @include _992{
            max-width: none;
            order: 2;
            position: absolute;
            width: 100%;
            padding: 20px 0 21px;
            z-index: 1;
            padding: 20px 21px;
        }
        &-text{
            p{
                font-weight: 700;
                font-size: 48px;
                line-height: 55.2px;
                color: $text-clr;
                margin: 0 0 28px;
                @include _1920{
                    font-size: calcFluid(32, 48);
                    line-height: calcFluid(36.80, 55.2);
                    margin: 0 0 calcFluid(18.67, 28);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                    margin: 0 0 81px;
                    top: 48%;

                }
            }
            span{
                display: inline-block;
                font-size: 18px;
                line-height: 22.5px;
                color: $text-clr;
                margin: 0 0 30px;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                    margin: 0 0 calcFluid(20, 30);
                }
                @include _992{
                    font-size: 12px;
                    line-height: 15px;
                    margin-right: 0px;
                }
            }
        }
        &-link{
            width: 255px;
            height: 60px;
            margin-top: 13px;
            @include _1920{
                width: calcFluid(170, 255);
                height: calcFluid(40, 60);
                margin-top: calcFluid(8.67, 13);
            }
            @include _992{
                width: 100%;
                height: 52px;
                margin-top: 30px;
            }
            span{
                font-size: 18px;
                line-height: 30px;
                font-weight: 700;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
    &__image{
        height: 495px;
        width: 996px;
        position: relative;
        @include _1920{
            height: calcFluid(330, 495);
            width: calcFluid(664, 996);
        }
        @include _992{
            width: 100%;
            height: 151px;
            order: 1;
            margin-bottom: 237px;
            position: relative;
        }
        img{
            width: calc(100% + 5px);
            margin-left: -5px;
            height: 100%;
            object-fit: cover;
        }
        &::after{
            content: '';
            background-color: rgba(255, 255, 255, .8);
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }

    }
}
#page-container{
    padding: 0;
}